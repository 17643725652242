<template>
  <div>
    <div class="home">
      <div class="flex-col center" style="row-gap: 80px">
        <div class="flex-col center" style="row-gap: 60px; width: 100%">
          <div>
            <icon-set icon="logo" height="60px"></icon-set>
            <icon-set icon="logo-text" height="35px" style="margin-left: 24px"></icon-set>
          </div>
        </div>
        <div class="flex-col center" style="row-gap: 40px">
          <div class="text-on-line">
            <span>BROWSE BY CATEGORY</span>
          </div>
          <div class="flex center">
            <div class="categories flex center">
              <router-link v-for="cat in categories" custom :to="{name: 'Browse', params: { category: cat.name } }" :key="cat.name" v-slot="{href, navigate}">
                <a :href="href" @click="navigate" class="category flex-col center">
                  <div><icon-set class="unfilled" :icon="cat.icon" height="40px"/></div>
                  <div class="label">{{cat.label}}</div>
                  <div class="count">
                    <span v-show="getCategoryCount(cat.value)">{{getCategoryCount(cat.value) | formattedNumber }} Entries</span>
                  </div>
                </a>
              </router-link>
            </div>
          </div>
        </div>
        <div id="popgames" class="flex-col center" style="row-gap: 40px">
          <div class="text-on-line">
            <span>POPULAR GAMES</span>
          </div>
          <div style="margin-top: 15px">
            <div class="list-container flex-col">
              <div class="list flex center">
                <router-link custom :to="{ name: 'Details', params: { id: game.id } }" v-for="game in popularGames" :key="game.id" v-slot="{href, navigate}">
                  <a :href="href" @click="navigate" class="item" style="position: relative; border-radius: 10px;">
                    <div class="overlay">
                      <h6 class="title">{{game.title}}</h6>
                      <platform :platform-id="game.platform_id"></platform>
                    </div>
                    <g-image blur-hash fluid
                      :image-object="game.image"
                      :draw-width="200"
                      :draw-height="275"
                      :alt="'Image of ' + game.title"
                      :image-size-idx="3"
                    ></g-image>
                  </a>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconSet from '@/components/IconSet';
import GImage from '@/components/GImage';
import Platform from '@/components/Platform';
import _ from 'underscore';
import Vue from 'vue';

export default {
  name: "HomePage",
  components: {
    IconSet,
    GImage,
    Platform
  },
  props: {
    showAccount: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      popularGames: [],
      categoryCounts: {}
    }
  },
  watch: {
    showAccount: {
      handler (newVal) {
        if (newVal) this.$emit('showAccount')
      },
      immediate: true
    } 
  },
  created () {
    this.getPopularGames();
    this.getCategoryCounts();
  },
  methods: {
    searched (search) {
      // Duplicated... should combine with this in Navigation somehow
      this.$router.push({ name: 'Browse', query: { q: search }})
    },
    getPopularGames () {
      return this.$store.dispatch('meta/getPopularGames', this.id)
      .then(response => {
        this.popularGames = response.records;
      })
    },
    getCategoryCounts () {
      return this.$store.dispatch('meta/getCategoryCounts')
      .then(response => {
        let total = 0;
        _.each(response.counts, obj => {
          Vue.set(this.categoryCounts, obj.category_id, obj.count);
          total += obj.count;
        })
        this.categoryCounts.ALL = total;
      })
    },
    getCategoryCount (categoryId) {
      let cat = _.find(this.categories, c => {
        return c.value === categoryId ||
          _.has(c, "children") && _.contains(_.pluck(c.children, "value"), categoryId);
      });
      let catId = cat.value === undefined ? 'ALL' : categoryId;
      return _.has(cat, "children") ? 
        _.reduce(cat.children, (memo, c) => {
          return memo + this.categoryCounts[c.value];
        }, 0)
        : this.categoryCounts[catId];
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_variables.scss";

.home {
  margin: 60px auto;
  max-width: $smallWidth;
}

.home > .flex-col > * {
  width: 100%;
}

.text-center {
  text-align: center;
}

.text-on-line {
  width: 95%;
  border-bottom: 1px solid #4e5158;
  text-align: center;
  color: #ddd;
  line-height: 0;
  > * {
    background: var(--background-color);
    padding: 0 24px;
  }
}

.categories, #popgames {
  a {
    color: white;
    text-decoration: none;
  }
}

.categories {
  column-gap: 16px;
  flex-wrap: wrap;
  > .category {
    padding: 15px;
    border-radius: 10px;
    fill: rgb(196, 196, 196);
    min-width: 110px;
    &:hover {
      cursor: pointer;
      background-color: rgba(48, 48, 48, 0.2);
    }
    > .label {
      margin-top: 8px;
    }
    > .count {
      font-size: 0.6rem;
      color: rgb(187, 187, 187);
      min-height: 0.7rem;
    }
  }
}

.list-container {
  margin-bottom: 35px;
}

.list-container > .list {
  flex-wrap: wrap;
  gap: 20px;
}

.list-container > .title {
  font-weight: bold;
  margin-bottom: 20px;
}

.list-container > .list > .item {
  width: 175px;
  height: 240px;
  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    top: 0;
    left: 0;
    z-index: 20;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 10px 15px;
    transition: all 0.2s;
    &:hover {
      opacity: 100%;
      background: linear-gradient(0deg, rgba(38,38,38,1) 0%, transparent 100%);
      cursor: pointer;
    }
  }
  ::v-deep .blur-hash-img > div {
    > canvas {
      border-radius: 7px;
    }
  }
}
</style>
