<template>
  <div class="browse-container">
    <div class="g-container">
      <div class="flex list-details-container">
        <div class="filters-container" v-if="!hideFilters">
          <search-filters :route-name="$route.name" :filters="filters" @selected="filtersChanged" ref="filters" :category="activeCategory" :collection-type="activeCollectionType" :hide-categories="hideCategoriesFilter"></search-filters>
          <slot name="extraFilters"></slot>
          <div class="pending-entries-filters" v-if="!isUserCollection && loggedInUser?.verified">
            <div class="filter-header">Show Only:</div>
            <b-form-group v-slot="{ ariaDescribedby }">
              <b-form-checkbox-group
                id="user-entries-checkbox-group"
                v-model="selectedPendingUserEntriesFilters"
                :options="userDataStateOptions"
                :aria-describedby="ariaDescribedby"
                name="user-entries-checkbox">
              </b-form-checkbox-group>
            </b-form-group>
          </div>
        </div>
        <div class="content-container flex">
          <div class="content flex">
            <div class="list">
              <div class="head" v-if="!hideHeader">
                <div class="head-search flex">
                  <div v-if="searchable" style="flex-grow: 1; position: relative" class="search flex-col">
                    <b-form-input style="min-width: 320px; max-width: 480px; margin-right: 4px; height: 30px" placeholder="search" type="text" v-model="search" debounce="500" @update="searchChanged" />
                    <slot name="extra-search-below"></slot>
                  </div>
                  <div>
                    <slot name="extra-search"></slot>
                  </div>
                </div>
                <div class="flex" style="column-gap: 10px">
                  <div style="flex-grow: 1">
                    <div v-if="!hideTitle" style="font-size: 1.4rem; font-weight: bold; color: white; margin-bottom: 2px">{{category | capitalize}} Search Results</div>
                    <div v-if="totalItems !== undefined" style="font-size: 1rem; font-weight: 300">
                      <span>{{totalItems | formattedNumber}} Results </span>
                      <span v-if="query">
                        for "<span style="font-weight:bold">{{query}}</span>"
                      </span>
                    </div>
                  </div>
                  <div>
                    <slot name="extra-results"></slot>
                  </div>
                  <div class="sort" v-if="!hideSortOptions">
                    <span class="label">Sort Mode:</span>
                    <b-form-select v-model="sortSelected" :options="cSortOptions"></b-form-select>
                  </div>
                  <div class="display-type" style="margin-left: 20px" v-if="!hideDisplayTypeOptions">
                    <span class="label">Display Type:</span>
                    <b-form-select v-model="displayTypeSelected" :options="displayTypeOptions"></b-form-select>
                  </div>
                </div>
              </div>
              <div :class="['flex items', displayTypeSelected]" style="max-width: 100%; flex-wrap: wrap" :key="category" v-if="!loading">
                <template v-for="(item, itemIndex) in formattedItems">
                  <slot name="beforeListItem" :item="item"></slot>
                  <slot name="listItem" :scope="item">
                    <list-item :item="item" :user-data="userItemForIndex(itemIndex)" @selected="navToItemWithIndex(itemIndex)" :selected="isSelectedItem(selectedItem, item.id)" :display-type="cDisplayType" :direct-link="selectableItems ? undefined : getDetailsLink(item.id)" :key="itemIndex + item.id">
                      <template slot="before"><slot name="inlineBeforeListItem" :item="item"></slot></template>
                      <template slot="after"><slot name="inlineAfterListItem" :item="item"></slot></template>
                    </list-item>
                  </slot>
                  <slot name="afterListItem" :item="item"></slot>
                </template>
                <div v-if="un.isEmpty(formattedItems)" style="width: 100%; height: 90px; color: #656565" class="flex center v-center italic">No Items to Display</div>
              </div>
              <div style="position: relative; width: 100%; min-height: 180px" class="flex center v-center" v-if="loading">
                <b-spinner label="Retrieving items..." />
              </div>
              <div class="flex pagination-container">
                <div class="flex v-center">
                  <b-form-select v-model="curPageSize" :options="$options.PAGE_OPTIONS" size="sm"></b-form-select>
                  <span style="color: #656565; font-size: 0.9em; white-space: nowrap">&nbsp;&nbsp; per page</span>
                </div>
                <div style="flex-grow: 1">
                  <pagination v-if="itemsList && totalItems" :is-nav="filtersInUrl" v-model="curPage" :total-rows="totalItems" :per-page="lPageSize" align="right" :size="mediumWidth ? 'sm' : 'md'" first-number last-number></pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <slot name="extra"></slot>
  </div>
</template>

<script>
// @ is an alias to /src
import browseMixin from '@/mixins/BrowseItemsMixin';
import ListItem from "@/components/EncyclopediaListItem";
import { BFormSelect, BFormInput, BSpinner } from 'bootstrap-vue';
import Pagination from "@/components/Pagination";
import SearchFilters from '@/components/SearchFilters';
import _ from "underscore";
import '@trevoreyre/autocomplete-vue/dist/style.css';

export default {
  name: "BrowseItems",
  metaInfo () {
    return {
      title: "Browse | GAMEYE",
      description: `Browse through ${this.category} using the GAMEYE Encyclopedia`,
      meta: [
        {vmid: "canonical", property: "canonical", content: window.location.origin + this.$route.fullPath}
      ]
    }
  },
  components: {
    ListItem,
    BFormSelect,
    BFormInput,
    BSpinner,
    Pagination,
    SearchFilters
  },
  mixins: [browseMixin],
  props: {
    hideHeader: {
      type: Boolean,
      default: false
    },
    hideTitle: {
      type: Boolean,
      default: false
    },
    hideSortOptions: {
      type: Boolean,
      default: false
    },
    hideDisplayTypeOptions: {
      type: Boolean,
      default: false
    },
    hideCategoriesFilter: {
      type: Boolean,
      default: false
    },
    hideFilters: {
      type: Boolean,
      default: false
    },
    selectableItems: {
      type: Boolean,
      default: false
    },
    selectOnlyOne: {
      type: Boolean,
      default: false
    },
    itemFormatter: {
      type: Function,
      default: i => { return i; }
    },
    itemFilterFunc: {
      type: Function,
      default: () => { return true; }
    },
    collectionSortingOptionsFilterFunc: {
      type: Function,
      default: (sortOption) => { return sortOption.encyclopediaExclusive === undefined; }
    },
    encyclopediaSortingOptionsFilterFunc: {
      type: Function,
      default: (sortOption) => { return sortOption.libraryExclusive === undefined; }
    },
    searchable: {
      type: Boolean,
      default: false
    },
    sortingOptions: {
      type: Array
    },
    // expects object of params to override
    overrideParams: {
      type: Object
    },
    isUserCollection: {
      type: Boolean,
      default: false
    },
    collectionType: {
      type: Number,
      default: undefined
    }
  },
  PAGE_OPTIONS: [25, 50, 100],
  data () {
    return {
      lPage: this.page || 1,
      lPageSize: this.pageSize || 25,
      loading: false,
      sortSelected: 'title_asc',
      displayTypeSelected: 'List',
      selectedPendingUserEntriesFilters: [],
      sortOptions: [
        {
          text: 'Title: A - Z',
          value: 'title_asc',
          dbVal: 0,
          asc: 1
        },
        {
          text: 'Title: Z - A',
          value: 'title_desc',
          dbVal: 0,
          asc: 0
        },
        {
          text: '$: Lowest',
          value: 'price_asc',
          dbVal: 2,
          asc: 1,
          libraryExclusive: true
        },
        {
          text: '$: Highest',
          value: 'price_desc',
          dbVal: 2,
          asc: 0,
          libraryExclusive: true
        },
        {
          text: 'Loose $: Lowest',
          value: 'loose_asc',
          dbVal: 3,
          asc: 1,
          encyclopediaExclusive: true
        },
        {
          text: 'Loose $: Highest',
          value: 'loose_desc',
          dbVal: 3,
          asc: 0,
          encyclopediaExclusive: true
        },
        {
          text: 'CiB $: Lowest',
          value: 'cib_asc',
          dbVal: 4,
          asc: 1,
          encyclopediaExclusive: true
        },
        {
          text: 'CiB $: Highest',
          value: 'cib_desc',
          dbVal: 4,
          asc: 0,
          encyclopediaExclusive: true
        },
        {
          text: 'New $: Lowest',
          value: 'new_asc',
          dbVal: 5,
          asc: 1,
          encyclopediaExclusive: true
        },
        {
          text: 'New $: Highest',
          value: 'new_desc',
          dbVal: 5,
          asc: 0,
          encyclopediaExclusive: true
        },
        {
          text: 'Added Date: Newest',
          value: 'date_asc',
          dbVal: 6,
          asc: 0
        },
        {
          text: 'Added Date: Oldest',
          value: 'date_desc',
          dbVal: 6,
          asc: 1
        },
        {
          text: 'Modified Date: Most Recent',
          value: 'modified_asc',
          dbVal: 7,
          asc: 0
        },
        {
          text: 'Modified Date: Oldest',
          value: 'modified_desc',
          dbVal: 7,
          asc: 1
        },
        {
          text: 'Release Date: Most Recent',
          value: 'release_desc',
          dbVal: 1,
          asc: 0
        },
        {
          text: 'Release Date: Oldest',
          value: 'release_asc',
          dbVal: 1,
          asc: 1
        },
        {
          text: 'Metacritic Score: Best',
          value: 'metascore_desc',
          dbVal: 8,
          asc: 0
        },
        {
          text: 'Metacritic Score: Worst',
          value: 'metascore_asc',
          dbVal: 8,
          asc: 1
        }
      ],
      displayTypeOptions: ['List', 'Grid'],
      userDataStateOptions: [{text: 'My Pending', value: 3}, {text: 'My Accepted', value: 1}], // 3 is needs review, 1 is good/accepted
      selectedItem: undefined,
      typeSelected: 0,
      selectedFiltersObj: {}, // this is a copy of what's in the filters component.. not ideal. hopefully can find a better solution
      registeredChildItems: [],
      search: '',
      windowResizeMethod: _.debounce(() => {
          this.$nextTick(() => {
            if (window.innerWidth <= 1092) this.mediumWidth = true;
            else this.mediumWidth = false;
          })
        }, 200),
      mediumWidth: false,
      un: _ //for use in html
    }
  },
  created () {
    this.windowResizeMethod();
    window.addEventListener("resize", this.windowResizeMethod);
  },
  mounted () {
    if (this.sort) this.sortSelected = this.sort;
    if (this.displayType) this.displayTypeSelected = this.displayType;
    // Required to force refresh on data when coming from browse page
    // - if data already loaded in list, watchers don't trigger on computed initially
    //   so it doesn't look like a change
    if (this.filtersInUrl) {
      this.$watch('$route.params', () => {
        this.getItems();
      }, { immediate: true });
    } else {
      this.getItems();
    }
  },
  beforeUnmount () {
    window.removeEventListener("resize", this.windowResizeMethod);
  },
  computed: {
    curPage: {
      get () {
        return this.lPage;
      },
      set (v) {
        if (v) this.pageChanged(v);
      }
    },
    curPageSize: {
      get () {
        return this.lPageSize;
      },
      set (v) {
        if (v) this.pageSizeChanged(v);
      }
    },
    formattedItems () {
      return _.filter(_.map(this.itemsList, this.itemFormatter), this.itemFilterFunc);
    },
    formattedUserItems () {
      return _.filter(_.map(this.userItemsList, this.itemFormatter), this.itemFilterFunc);
    },
    activeCategory () {
      return _.findWhere(this.categories, {name: this.category});
    },
    activeCollectionType () {
      return _.findWhere(this.collectionTypes, {value: this.collectionType});
    },
    cDisplayType () {
      return this.mediumWidth ? "Grid" : this.displayTypeSelected;
    },
    cSortOptions () {
      return this.sortingOptions || 
        this.isUserCollection ? 
        _.filter(this.sortOptions, this.collectionSortingOptionsFilterFunc) :
        _.filter(this.sortOptions, this.encyclopediaSortingOptionsFilterFunc);
    }
  },
  watch: {
    page (newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.lPage = newVal;
    },
    curPage (newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getItems();
    },
    pageSize (newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.lPageSize = newVal;
    },
    curPageSize (newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getItems();
    },
    sortSelected (newVal, oldVal) {
      if (newVal !== oldVal) this.sortChanged(newVal);
    },
    displayTypeSelected (newVal, oldVal) {
      if (newVal !== oldVal) this.displayTypeChanged(newVal);
    },
    selectedPendingUserEntriesFilters (newVal, oldVal) {
      if (newVal != oldVal) this.userEntriesCheckboxGroupChanged()
    }
  },
  methods: {
    pageChanged (newVal) {
      if (newVal && this.filtersInUrl) this.addToQueryParam('p', newVal.toString());
      else if (newVal) this.lPage = newVal;
    },
    pageSizeChanged (newVal) {
      if (newVal && this.filtersInUrl) this.addToQueryParam('ps', newVal.toString());
      else if (newVal) this.lPageSize = newVal;
    },
    getDetailsLink (id) {
      return this.$router.resolve({ name: 'Details', params: { id: id } }).href;
    },
    registerItem (childItem) {
      this.registeredChildItems.push(childItem);
    },
    selectItem (childItem, isSelected) {
      // TODO: should probably put more "selected" logic in this component. Currently in child components..
      //         should keep selecteditems list and probably manage some actions on them. Look into later
      if (this.selectOnlyOne && isSelected) {
        _.each(this.registeredChildItems, ci => {
          ci.selected = ci === childItem;
        })
      }
    },
    deselectAllItems () {
      _.each(this.registeredChildItems, ci => {
        ci.selected = false;
      })
    },
    userItemForIndex (itemIndex) {
      return this.formattedUserItems ? this.formattedUserItems[itemIndex] : undefined
    },
    navToItemWithIndex(itemIndex) {
      if (this.formattedUserItems !== undefined && this.formattedUserItems.length > itemIndex) {
        this.navToUserItem(this.formattedUserItems[itemIndex].uuid)
      } else {
        this.navToItem(this.formattedItems[itemIndex].id)
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_variables.scss";

.item-wrapper {
  &.grid-type {
    width: 28%;
    min-width: 325px;
  }
}

.list {
  width: 100%;
  .search > input {
    padding: 3px 8px;
    height: calc(1.5em + 3px + 2px);
  }
  .items {
    row-gap: 30px;
    column-gap: 15px;
  }
}

.list-details-container {
  flex-direction: row;
}

.details-container {
  margin-left: 30px;
  min-width: 500px;
  width: 50%;
  > .no-item {
    width: 100%;
    border: 1px dashed #707070;
    text-align: center;
    color: #707070;
    padding: 100px 0;
    font-style: italic;
  }
}

.header .icons .category-icon:not(:first-child) {
  margin-left: 5px;
}

.content-container {
  flex-direction: column;
  padding-left: 20px;
  flex-grow: 3;
}

.filters-container {
  border-right: 1px solid #C4C4C4;
  padding-right: 16px;
}

.pending-entries-filters {
  padding-left: 16px;
}

.content {
  flex-direction: row;
}

.list .head {
  flex-direction: row;
  align-items: end;
  padding: 5px 0;
  margin-bottom: 20px;
  color: var(--grey-font-color);
  .head-search {
    margin-bottom: 8px;
  }
  .sort {
    text-align: right;
  }
  .label {
    margin-right: 10px;
  }
  .custom-select {
    height: auto;
    width: auto;
  }
}

.browse-container > .g-container {
  max-width: $bodyWidth;
  margin: 0 auto;
}

.pagination-container {
  margin: 32px 0 16px;
}

@media (max-width: calc($mediumWidth + 100px)) {
  .List::v-deep .item-wrapper.grid-type {
    width: 100%;
  }
}

@media (max-width: $smallWidth) {
  .browse-container > .g-container {
    padding: 0;
    .content-container, .filters, .filters-container {
      padding: 0;
    }
    .item {
      width: 100%;
    }
  }
  .list-details-container {
    display: flex;
    flex-direction: column;
  }
  .filters-container::v-deep {
    border: none;
    .inputs {
      display: flex;
      column-gap: 10px;
    }
  }
  .display-type {
    display: none;
  }
  .head .sort select.custom-select {
    width: 100%;
  }
  .filters::v-deep .autocomplete-result-list {
    right: 0;
  }
}

.filter-header {
  font-weight: bold;
  font-size: 0.9rem;
  color: #A9A9A9;
  margin-bottom: 8px;
}
</style>
